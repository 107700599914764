.section {
    height: 90vh;
}

.totop {
    position: fixed;
    z-index: 1000;
    bottom: 20px;
    right: 20px;
}

.tablehead {
    position: sticky;
    top: 60px;
    z-index: 100;
}

.tabletitle {
    text-transform: uppercase;
    background-color: gainsboro;
}