.screen {
  position: fixed; /* given position fixed to solve white border */
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 100%;
  width: 100%;
  filter: blur(10px) brightness(0.5);
  transform: scale(1.1);
  overflow: hidden;
  z-index: -10;
}
.align {
  height: 100vh;
}

.fieldset {
  display: flex;
  flex-direction: column;
}

.submit {
  position: absolute;
  right: 18px;
  bottom: -18px;
  font-weight: bold;
  background-color: #585657;
  color: white;
  width: 35%;
  cursor: pointer;
}
.submit:active {
  background-color: #585657;
  color: white;
}
.submit:current {
  background: inherit;
  color: inherit;
}
@media (max-width: 576px) {
  .submit {
    width: 60%;
  }
}
@media (max-width: 1200px) and (min-width: 577px) {
  .submit {
    width: 30%;
  }
}

.legend {
  width: auto;
  padding: 15px;
}

.formstyle {
  border-radius: 20px;
}
