/* CARDS */

.card img {
  filter: blur(5px) brightness(0.6);
  transition: 0.5s;
}
.card:hover img {
  filter: blur(0) brightness(0.9);
}
.card h4 {
  opacity: 1;
  transition: 0.5s;
}
.card:hover h4 {
  opacity: 0;
}

/* .cardImg {
    position: absolute;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    padding: 0;
} */

/* CARDS END */

/* TOP BAR */

.z {
  z-index: 1000;
  overflow: hidden;
}
/* TOP BAR */

/* DELETE BUTTON */

.loading {
    width: 20px !important;
    height: 20px !important;
    background: transparent;
    color: black !important;
}

/* DELETE BUTTON */

/* EDIT - CREATE */

.inputClass {
    height: 35px;
    border-radius: 12px;
    box-shadow: none;
    border: none;
    padding: 5px;
}

.labelHead {
    font-size: 16px;
}

/* EDIT - CREATE */