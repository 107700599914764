/*--------------------------
      Global Styles
---------------------------*/

body {
  overflow-x: hidden;
  background-color: #ffffff;
  font-size: 14px;
  font-weight: 400;
  color: #9b9b9b;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  box-sizing: border-box;
}
* {
  scroll-behavior:auto;
}

::selection {
  background-color: green;
  color: #ffffff;
}

a {
  color: inherit;
  transition: all 0.3s linear;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -ms-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
}

a:hover {
  color: inherit;
  text-decoration: none;
}

textarea {
  resize: none;
}

section {
  position: relative;
  padding-top: 100px;
  padding-bottom: 100px;
}

img {
  max-width: 100%;
}

/*-------------------------
     RESET Default Styles
 --------------------------*/
*,
p,
h1,
h2,
h3,
h4,
h5,
h6,
ul,
ol,
li {
  margin-top: 0;
  padding-top: 0;
}

*,
:active,
:focus {
  outline: none;
}

ul,
ol {
  list-style: none;
}

button {
  border: none;
  padding: 0;
}

button,
button:focus,
.btn.focus,
.btn:focus,
.form-control,
.form-control:focus {
  outline: none;
  background-color: transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -ms-box-shadow: none;
  -o-box-shadow: none;
  box-shadow: none;
}

textarea {
  resize: none;
}

.btn:active {
  opacity: 1 !important;
}

/*
.button:active {
  background-color: black;
}*/

/*
Link:active {
  background-color: black;
} */
